<template>
  <div class="animated fadeIn leave-list">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <div class="d-flex">
              <h3>ویزیت‌های من</h3>
            </div>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-title>
      <v-tabs fixed-tabs background-color="transparent" v-model="tab">
        <v-tab class="tab-title"> ویزیت‌های رزرو شده</v-tab>
        <v-tab class="tab-title"> ویزیت‌های انجام شده </v-tab>
      </v-tabs>
      <v-card class="br-card">
        <v-tabs-items v-model="tab">
          <!-- reserved visits -->
          <v-tab-item>
            <v-card class="cards pa-3">
              <v-card-text>
                <div>
                  <div class="h4 mb-4" v-if="role == 'doctor'">
                    <span class="h3">
                      <v-icon class="purple--text"
                        >mdi-comment-alert-outline</v-icon
                      >
                      سطر‌هایی که به رنگ بنفش هستند، ویزیت‌هایی هستند که از طریق
                      نوبت دهی به ثبت رسیده‌اند.</span
                    >
                  </div>
                  <v-text-field
                    v-model="reservedFilter"
                    prepend-inner-icon="mdi-magnify"
                    label="جستجو"
                    single-line
                    hide-details
                    filled
                    rounded
                    clearable
                    class="text-right w-80 mt-2 mb-2 search-input"
                  ></v-text-field>
                  <b-table
                    responsive
                    show-empty
                    :fields="computedFields"
                    :items="reservedItems"
                    empty-text="ویزیتی برای نمایش وجود ندارد."
                    empty-filtered-text="ویزیتی برای نمایش وجود ندارد."
                    :filter="reservedFilter"
                    :current-page="reservedCurrentPage"
                    :per-page="reservedPerPage"
                    @filtered="reservedOnFiltered"
                    :tbody-tr-class="rowClass"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.value }}
                      </div>
                    </template>
                    <template v-slot:cell(date)="data">
                      <div class="text-center v-align pt-md-2">
                        {{
                          data.item.date ? data.item.date.split("-")[0] : "-"
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(time)="data">
                      <div class="text-center v-align pt-md-2">
                        {{
                          data.item.date ? data.item.date.split("-")[1] : "-"
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(operation)="data">
                      <div style="text-align: center; vertical-align: middle">
                        <v-btn
                          class="ms-2 me-2 py-3"
                          :to="
                            role == 'nurse' || role == 'dentistryNurse'
                              ? '/nurse/visitDetails/' + data.item.id
                              : '/doctor/visitDetails/' + data.item.id
                          "
                          :class="
                            data.item.status == 'رزرو'
                              ? 'purple-btn'
                              : 'primary-btn'
                          "
                        >
                          <v-icon class="me-1">mdi-note-edit-outline</v-icon>
                          جزئیات
                          {{ deviceType == "mobile" ? "" : "ویزیت" }}
                        </v-btn>
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          data.index +
                          reservedPerPage * (reservedCurrentPage - 1) +
                          1
                        }}
                      </div>
                    </template>
                    <div
                      slot="table-busy"
                      class="text-center primary--text my-2"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>
                  <v-pagination
                    v-model="reservedCurrentPage"
                    :length="Math.ceil(reservedTotalRows / reservedPerPage)"
                    :total-visible="5"
                    prev-icon="arrow_back"
                    next-icon="arrow_forward"
                    style="float: center"
                  ></v-pagination>
                  <v-select
                    label="تعداد در هر صفحه:"
                    style="width: 150px"
                    v-model="reservedPerPage"
                    :items="perPageOptions"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- done visits -->
          <v-tab-item>
            <v-card class="cards pa-3">
              <v-card-text>
                <div>
                  <v-text-field
                    v-model="doneFilter"
                    prepend-inner-icon="mdi-magnify"
                    label="جستجو"
                    single-line
                    hide-details
                    filled
                    rounded
                    clearable
                    class="text-right w-80 mt-2 mb-2 search-input"
                  ></v-text-field>
                  <b-table
                    responsive
                    show-empty
                    :fields="computedFields"
                    :items="doneItems"
                    empty-text="ویزیتی برای نمایش وجود ندارد."
                    empty-filtered-text="ویزیتی برای نمایش وجود ندارد."
                    :filter="doneFilter"
                    :current-page="doneCurrentPage"
                    :per-page="donePerPage"
                    @filtered="doneOnFiltered"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.value }}
                      </div>
                    </template>
                    <template v-slot:cell(date)="data">
                      <div class="text-center v-align pt-md-2">
                        {{
                          data.item.date ? data.item.date.split("-")[0] : "-"
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(time)="data">
                      <div class="text-center v-align pt-md-2">
                        {{
                          data.item.date ? data.item.date.split("-")[1] : "-"
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(operation)="data">
                      <div style="text-align: center; vertical-align: middle">
                        <v-btn
                          class="primary-btn py-3"
                          :to="
                            role == 'nurse' || role == 'dentistryNurse'
                              ? '/nurse/visitDetails/' + data.item.id
                              : '/doctor/visitDetails/' + data.item.id
                          "
                        >
                          <v-icon class="me-1">mdi-note-edit-outline</v-icon>
                          جزئیات
                          {{ deviceType == "mobile" ? "" : "ویزیت" }}
                        </v-btn>
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          data.index + donePerPage * (doneCurrentPage - 1) + 1
                        }}
                      </div>
                    </template>
                    <div
                      slot="table-busy"
                      class="text-center primary--text my-2"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>
                  <v-pagination
                    v-model="doneCurrentPage"
                    :length="Math.ceil(doneTotalRows / donePerPage)"
                    :total-visible="5"
                    prev-icon="arrow_back"
                    next-icon="arrow_forward"
                    style="float: center"
                  ></v-pagination>
                  <v-select
                    label="تعداد در هر صفحه:"
                    style="width: 150px"
                    v-model="donePerPage"
                    :items="perPageOptions"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      deviceType: "",
      doneCurrentPage: 1,
      donePerPage: 10,
      reservedCurrentPage: 1,
      reservedPerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      doneTotalRows: "",
      reservedTotalRows: "",
      doneFilter: "",
      reservedFilter: "",
      Busy: true,
      Fields: [
        { key: "index", label: "#" },
        { key: "patientName", label: "نام بیمار" },
        { key: "date", label: "تاریخ" },
        { key: "time", label: "ساعت ملاقات" },
        { key: "operation", label: "عملیات" },
      ],
      doneItems: [],
      reservedItems: [],
      role: "",
      tab: null,
      infoDialog: false,
      shiftDoctor: "",
      isDentist: "",
    };
  },
  computed: {
    computedFields() {
      if (this.deviceType == "mobile") {
        return [
          { key: "patientName", label: "نام بیمار" },
          { key: "operation", label: "عملیات" },
        ];
      } else {
        return [
          { key: "index", label: "#" },
          { key: "patientName", label: "نام بیمار" },
          { key: "date", label: "تاریخ" },
          { key: "time", label: "ساعت ملاقات" },
          { key: "operation", label: "عملیات" },
        ];
      }
    },
  },
  methods: {
    getVisitsList() {
      this.Busy = true;
      this.vLoading = true;
      if (this.isDentist == "true" || this.role == "dentistryNurse") {
        this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/medicalTeam/visits/assignee",
            {},
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.Busy = false;
            this.vLoading = false;
            if (res.status == 200) {
              this.doneItems = res.data.filter((x) => x.done == true);
              this.reservedItems = res.data.filter((x) => x.done == false);
              this.doneTotalRows = this.doneItems.length;
              this.doneCurrentPage = 1;
              this.reservedTotalRows = this.reservedItems.length;
              this.reservedCurrentPage = 1;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.vLoading = false;
            this.Busy = false;
          });
      } else {
        this.$http
          .post(
            this.baseUrl + "/clinic/medicalTeam/visits/assignee",
            {},
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.Busy = false;
            this.vLoading = false;
            if (res.status == 200) {
              this.doneItems = res.data.filter((x) => x.done == true);
              this.reservedItems = res.data.filter((x) => x.done == false);
              this.doneTotalRows = this.doneItems.length;
              this.doneCurrentPage = 1;
              this.reservedTotalRows = this.reservedItems.length;
              this.reservedCurrentPage = 1;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.vLoading = false;
            this.Busy = false;
          });
      }
    },
    doneOnFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.doneTotalRows = filteredItems.length;
      this.doneCurrentPage = 1;
    },
    reservedOnFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.reservedTotalRows = filteredItems.length;
      this.reservedCurrentPage = 1;
    },
    rowClass(item) {
      if (item && item.status == "رزرو" && this.role == "doctor")
        return "table-row";
    },
    getDashboardInfo() {
      if (this.isDentist == "true" || this.role == "dentistryNurse") {
        this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/medicalTeam/dashboard/getInfo",
            {},
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              localStorage.setItem("shiftDoctor", res.data.shiftDoctor);
              this.shiftDoctor = res.data.shiftDoctor;
              this.$emit("doctorDashboard", res.data.shiftDoctor);
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.data, "error");
          });
      } else {
        this.$http
          .post(
            this.baseUrl + "/clinic/medicalTeam/dashbaord/getInfo",
            {},
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              localStorage.setItem("shiftDoctor", res.data.shiftDoctor);
              this.shiftDoctor = res.data.shiftDoctor;
              this.$emit("doctorDashboard", res.data.shiftDoctor);
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.data, "error");
          });
      }
    },
  },
  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.role = localStorage.getItem("role");
    this.isDentist = localStorage.getItem("isDentist");
    if (this.role == "doctor") {
      this.getDashboardInfo();
    }
    this.getVisitsList();
  },
};
</script>
<style>
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 82% !important;
}
.table-row {
  background-color: #c5cae9ac !important;
}
</style>
